<script setup>
import { onBeforeUnmount, onMounted, ref, defineProps } from "vue";
// import Btn from "@/components/display/Btn";
// import Icon from "@/components/display/icon/Icon";
import store from "@/store";
import Toast from "@/classes/Toast";

const props = defineProps({
  toast: Toast,
});

const timer = ref(props.toast?.timeout);
const interval = ref(null);
const countdownAmount = 25;

const close = () => {
  if (props.toast.dismissible) {
    store.dispatch("toasts/remove", props.toast);
  }
};

onMounted(() => {
  if (timer.value && timer.value > 0) {
    interval.value = setInterval(() => {
      if (timer.value <= 0) {
        close();
      }
      timer.value -= countdownAmount;
    }, countdownAmount);
  }
});

onBeforeUnmount(() => {
  if (interval.value) {
    clearInterval(interval.value);
  }
});
</script>

<template>
  <div
    class="toast relative bg-white mb-4 flex flex-col rounded-lg overflow-hidden box-shadow-std border border-gray-200 text-xl"
    style="max-width: 300px"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div
      class="toast-timer h-1"
      :class="'toast-timer-variant-' + toast.variant"
      :style="{ width: toast.timeout ? `${(timer / toast.timeout) * 100}%` : '100%' }"
    ></div>
    <div
      class="toast-header flex flex-col px-4 py-2"
      :class="'toast-header-variant-' + toast.variant"
    >
      <div class="font-semibold font-sans" v-if="toast.title">{{ toast.title }}</div>
      <div class="font-sans mr-auto toast-message" v-for="(message, m) in toast.messages" :key="m">
        {{ message }}
      </div>
      <div class="w-full self-stretch">
        <div
          class="pt-2 underline cursor-pointer text-right text-base"
          @click="close"
          v-if="toast.dismissible"
        >
          close
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="pcss">
.toast-timer-variant-default {
  @apply bg-blue-400;
}

.toast-timer-variant-success {
  @apply bg-green-400;
}

.toast-timer-variant-error {
  @apply bg-red-500;
}
.toast-header-variant-default {
  @apply bg-white;
}
.toast-header-variant-success {
  @apply bg-green-100;
}

.toast-header-variant-error {
  @apply bg-red-100;
}
</style>
