import { createStore } from "vuex";
// import { make } from "@/request";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Toast from "@/classes/Toast";
import ErrorParse from "@/classes/ErrorParse";
import { markRaw } from "vue";
import contractJson from "@/assets/contract.json";
// import contractFamiliarsJson from "@/assets/contract-familiars.json";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: {
      namespaced: true,
      state: {
        address: null,
        user: null,
        connection: null,
      },
      mutations: {
        SET_ADDRESS(state, payload) {
          state.address = payload;
        },
        SET_USER(state, payload) {
          state.user = payload;
        },
        SET_CONNECTION(state, payload) {
          state.connection = {
            signer: markRaw(payload?.signer),
            provider: markRaw(payload?.provider),
            address: payload?.address,
            network: markRaw(payload?.network),
          };
        },
        CLEAR_CONNECTION(state) {
          state.connection = null;
        },
      },
      actions: {
        // async getUser({ commit }) {
        //   let request;
        //   try {
        //     request = await make({ name: "user" });
        //   } catch (e) {
        //     console.log("not logged in", { e });
        //     commit("SET_ADDRESS", null);
        //     commit("SET_USER", null);

        //     return;
        //   }

        //   commit("SET_ADDRESS", request?.wallet_address);
        //   commit("SET_USER", request);

        //   return;
        // },
        // async loginToken({ commit, dispatch }, token) {
        //   let request;
        //   try {
        //     request = await make({
        //       name: "loginToken",
        //       data: {
        //         token,
        //       },
        //     });
        //   } catch (e) {
        //     console.log("not logged in from token", { e });
        //     commit("SET_ADDRESS", null);
        //     commit("SET_USER", null);

        //     dispatch(
        //       "toasts/add",
        //       { message: e, variant: "error" },
        //       { root: true }
        //     );

        //     throw e;
        //   }

        //   let userRequest;
        //   try {
        //     userRequest = await make({ name: "user" });
        //   } catch (e) {
        //     console.log("not logged in", { e });
        //     commit("SET_ADDRESS", null);
        //     commit("SET_USER", null);

        //     return;
        //   }

        //   dispatch(
        //     "toasts/add",
        //     { message: "Logged In!", variant: "success" },
        //     { root: true }
        //   );

        //   commit("SET_ADDRESS", userRequest?.wallet_address);
        //   commit("SET_USER", userRequest);

        //   return;
        // },
        async clearListeners({ state }) {
          if (state?.connection?.provider) {
            state.connection.provider.provider.removeAllListeners();
          }
          return;
        },
        async startListeners({ commit, dispatch, state }) {
          await dispatch("clearListeners");

          if (state?.connection?.provider) {
            state?.connection?.provider.provider.on("accountsChanged", (accounts) => {
              console.log("account changed: ", accounts);
              dispatch("connect", true);
            });

            // Subscribe to chainId change
            state?.connection?.provider.provider.on("chainChanged", (chainId) => {
              console.log("Chain changed: ", chainId);
              dispatch("connect", true);
            });

            // Subscribe to session disconnection
            state?.connection?.provider.provider.on("disconnect", (code, reason) => {
              console.log("Walled disconnected: ", code, reason);
              commit("CLEAR_CONNECTION");
            });
          }

          return;
        },
        async connect({ commit, dispatch, state }, listenersOn) {
          await dispatch("clearListeners");

          const providerOptions = {
            walletconnect: {
              package: WalletConnectProvider, // required
              options: {
                infuraId: "3f08b2453c0f4e1eb26eb22b57fe57e3", // required
              },
            },
          };

          const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: false, // optional
            providerOptions, // required
          });

          let signer, provider, network;
          try {
            const cleared = await web3Modal.clearCachedProvider();
            const instance = await web3Modal.connect();
            provider = new ethers.providers.Web3Provider(instance);
            signer = await provider.getSigner();
            network = await provider.getNetwork();
          } catch (e) {
            console.log("web3 rejected", { e });
            dispatch(
              "toasts/add",
              {
                message: "Web3 connection request rejected by user",
                variant: "error",
              },
              { root: true }
            );
            commit("CLEAR_CONNECTION");
            return false;
          }

          const address = await signer.getAddress();

          commit("SET_CONNECTION", { signer, provider, address, network });

          if (listenersOn) {
            dispatch("startListeners");
          }

          return { signer, provider, address, network };
        },
        async disconnect({ commit, dispatch }) {
          await dispatch("clearListeners");
          commit("CLEAR_CONNECTION");
          return;
        },
        // async login({ commit, dispatch, state }) {
        //   if (state.address) {
        //     let loginCheck;
        //     try {
        //       loginCheck = await make({ name: "user" });
        //     } catch (e) {
        //       console.log("not logged in", { e });
        //       return;
        //     }

        //     commit("SET_ADDRESS", loginCheck?.wallet_address);
        //     commit("SET_USER", loginCheck);
        //     return;
        //   }

        //   const connectRequest = await dispatch("connect");

        //   if (!connectRequest) {
        //     return;
        //   }

        //   let signature;
        //   try {
        //     signature = await connectRequest.signer.signMessage(
        //       "Enter Castle Club"
        //     );
        //   } catch (e) {
        //     dispatch(
        //       "toasts/add",
        //       {
        //         message: "Web3 signature request rejected by user",
        //         variant: "error",
        //       },
        //       { root: true }
        //     );
        //     return;
        //   }

        //   try {
        //     const request = await make({
        //       name: "login",
        //       data: { address: connectRequest.address, signature },
        //     });
        //   } catch (e) {
        //     dispatch(
        //       "toasts/add",
        //       { message: ["Login error", e], variant: "error" },
        //       { root: true }
        //     );
        //     return;
        //   }

        //   let loggedInCheck;
        //   try {
        //     loggedInCheck = await make({ name: "user" });
        //   } catch (e) {
        //     console.log("not logged in", { e });
        //     return;
        //   }

        //   commit("SET_ADDRESS", connectRequest.address);
        //   commit("SET_USER", loggedInCheck);

        //   return;
        // },
        // async logout({ commit }) {
        //   try {
        //     const request = await make({ name: "logout" });
        //   } catch (e) {
        //     // return e;
        //   }

        //   commit("SET_ADDRESS", null);
        //   commit("SET_USER", null);
        // },
        // async authToken() {
        //   return await make({ name: "userAuthToken" });
        // },
        // async getTokens({ state }) {
        //   let tokens = null;

        //   try {
        //     tokens = await make({
        //       name: "getTokens",
        //       params: {
        //         address: state?.connection?.address,
        //       },
        //     });
        //   } catch (e) {
        //     console.log("couldn't get tokens", e);
        //   }

        //   return tokens;
        // },
        async mint({ state, dispatch }, qty) {
          if (!state.connection?.signer) {
            dispatch(
              "toasts/add",
              { message: "Must connect your wallet to mint", variant: "error" },
              { root: true }
            );
            return;
          }
          if (
            state?.connection?.network?.chainId !==
            parseInt(process.env.VUE_APP_CONTRACT_NETWORK_ID)
          ) {
            dispatch(
              "toasts/add",
              { message: "Must be on Ethereum Mainnet", variant: "error" },
              { root: true }
            );
            return;
          }

          let contractInstance = await new ethers.Contract(
            process.env.VUE_APP_CONTRACT_ADDRESS,
            contractJson.abi,
            state.connection.signer
          );

          let eligible = await contractInstance["eligible()"](); //.eligible();

          if (!eligible) {
            dispatch(
              "toasts/add",
              { message: "This wallet is not eligible to mint", variant: "error" },
              { root: true }
            );
            return;
          }

          let nextToken = await contractInstance.nextToken();
          let maxTokens = await contractInstance.maxTokens();

          if (nextToken.gt(maxTokens)) {
            dispatch(
              "toasts/add",
              { message: "No tokens remaining", variant: "error" },
              { root: true }
            );
            return;
          }

          let maxQuantity = await contractInstance.maxMintPerAddress();
          let quantityMinted = await contractInstance.tokensMinted(state.connection.address);

          if (quantityMinted.gte(maxQuantity)) {
            dispatch(
              "toasts/add",
              { message: "You have already minted the maximum tokens", variant: "error" },
              { root: true }
            );
            return;
          }

          let bigNumAmount = await contractInstance.price();
          let valMultiplied = bigNumAmount.mul(qty);
          let valForTransaction = valMultiplied;

          let shopAttempt;
          try {
            shopAttempt = await contractInstance["shop(uint256)"](qty, {
              value: valForTransaction,
            });
          } catch (e) {
            dispatch(
              "toasts/add",
              {
                message: [
                  "Transaction not completed",
                  e?.message,
                  e?.data?.message,
                  e?.error?.message,
                ],
              },
              { root: true }
            );
            return;
          }

          let confirmingSoon = dispatch(
            "toasts/add",
            {
              message: "Mint will confirm soon",
              variant: "success",
              timeout: false,
              dismissible: false,
            },
            { root: true }
          );

          let confirmation = await state.connection.provider.waitForTransaction(
            shopAttempt.hash,
            true
          );

          await delay(10000);

          dispatch("toasts/remove", confirmingSoon, { root: true });

          dispatch(
            "toasts/add",
            {
              message: "Mint complete!",
              variant: "success",
            },
            { root: true }
          );

          return true;
        },
        async mintDetails({ state, dispatch }) {
          if (!state.connection?.signer) {
            dispatch(
              "toasts/add",
              { message: "Must connect your wallet to mint", variant: "error" },
              { root: true }
            );
            return false;
          }
          if (
            state?.connection?.network?.chainId !==
            parseInt(process.env.VUE_APP_CONTRACT_NETWORK_ID)
          ) {
            dispatch(
              "toasts/add",
              { message: "Must be on Ethereum Mainnet", variant: "error" },
              { root: true }
            );
            return false;
          }

          let contractInstance = await new ethers.Contract(
            process.env.VUE_APP_CONTRACT_ADDRESS,
            contractJson.abi,
            state.connection.signer
          );

          let isEligible,
            maxTokens,
            price,
            tokensMinted,
            externalAccountBalance,
            maxMintPerAddress,
            nextToken;

          try {
            isEligible = await contractInstance["eligible()"](); //await contractInstance.eligible();
            maxTokens = await contractInstance.maxTokens();
            price = await contractInstance.price();
            tokensMinted = await contractInstance.tokensMinted(state.connection.address);
            externalAccountBalance = await contractInstance.getExternalAccountBalance();
            maxMintPerAddress = await contractInstance.maxMintPerAddress();
            nextToken = await contractInstance.nextToken();
          } catch (e) {
            console.log({ e });
            return false;
          }

          return {
            maxTokens: maxTokens?.toNumber(),
            price: ethers.utils.formatEther(price?.toString()),
            tokensMinted: tokensMinted?.toNumber(),
            externalAccountBalance: externalAccountBalance?.toNumber(),
            maxMintPerAddress: maxMintPerAddress?.toNumber(),
            nextToken: nextToken?.toNumber(),
            totalMinted: nextToken?.toNumber() ? nextToken?.toNumber() - 1 : 0,
            isEligible,
            maxMintable: isEligible ? maxMintPerAddress.sub(tokensMinted)?.toNumber() : null,
          };
        },
        async seeBalance({ state, dispatch }) {
          if (!state.connection?.signer) {
            dispatch(
              "toasts/add",
              { message: "Must connect your wallet to mint", variant: "error" },
              { root: true }
            );
            return false;
          }
          if (
            state?.connection?.network?.chainId !==
            parseInt(process.env.VUE_APP_CONTRACT_NETWORK_ID)
          ) {
            dispatch(
              "toasts/add",
              { message: "Must be on Ethereum Mainnet", variant: "error" },
              { root: true }
            );
            return false;
          }

          let contractInstance = await new ethers.Contract(
            process.env.VUE_APP_CONTRACT_ADDRESS,
            contractJson.abi,
            state.connection.signer
          );

          let balance;

          try {
            balance = await contractInstance.seeBalance();
          } catch (e) {
            console.log({ e });
            return false;
          }

          return {
            // balance: balance?.toNumber(),
            balanceEth: ethers.utils.formatEther(balance?.toString()),
          };
        },
        async withdraw({ state, dispatch }, data) {
          if (!state.connection?.signer) {
            dispatch(
              "toasts/add",
              { message: "Must connect your wallet to mint", variant: "error" },
              { root: true }
            );
            return false;
          }
          if (
            state?.connection?.network?.chainId !==
            parseInt(process.env.VUE_APP_CONTRACT_NETWORK_ID)
          ) {
            dispatch(
              "toasts/add",
              { message: "Must be on Ethereum Mainnet", variant: "error" },
              { root: true }
            );
            return false;
          }
          if (!data.recipient || !data.amount) {
            dispatch(
              "toasts/add",
              { message: "Recipient and amount required", variant: "error" },
              { root: true }
            );
            return false;
          }

          let contractInstance = await new ethers.Contract(
            process.env.VUE_APP_CONTRACT_ADDRESS,
            contractJson.abi,
            state.connection.signer
          );

          let attemptWithdraw;

          try {
            attemptWithdraw = await contractInstance.withdraw(
              data.recipient,
              ethers.utils.parseEther(data.amount)
            );
          } catch (e) {
            dispatch(
              "toasts/add",
              {
                message: [
                  "Transaction not completed",
                  e?.message,
                  e?.data?.message,
                  e?.error?.message,
                ],
              },
              { root: true }
            );
            return false;
          }

          let confirmingSoon = dispatch(
            "toasts/add",
            {
              message: "Withdrawal will confirm soon",
              variant: "success",
              timeout: false,
              dismissible: false,
            },
            { root: true }
          );

          let confirmation = await state.connection.provider.waitForTransaction(
            attemptWithdraw.hash,
            true
          );

          await delay(10000);

          dispatch("toasts/remove", confirmingSoon, { root: true });

          dispatch(
            "toasts/add",
            {
              message: "Withdrawal complete!",
              variant: "success",
            },
            { root: true }
          );

          return true;
        },

        // async claim({ state, dispatch }, tokenIds) {
        //   if (!tokenIds?.length) {
        //     dispatch(
        //       "toasts/add",
        //       { message: "Must select some tokens to claim", variant: "error" },
        //       { root: true }
        //     );
        //     return;
        //   }

        //   if (!state.connection?.signer) {
        //     dispatch(
        //       "toasts/add",
        //       { message: "Must connect your wallet to claim familiars", variant: "error" },
        //       { root: true }
        //     );
        //     return false;
        //   }
        //   if (
        //     state?.connection?.network?.chainId !==
        //     parseInt(process.env.VUE_APP_CONTRACT_NETWORK_ID)
        //   ) {
        //     dispatch(
        //       "toasts/add",
        //       { message: "Must be on Ethereum Mainnet", variant: "error" },
        //       { root: true }
        //     );
        //     return false;
        //   }

        //   let contractInstance = await new ethers.Contract(
        //     process.env.VUE_APP_FAMILIARS_CONTRACT_ADDRESS,
        //     contractFamiliarsJson.abi,
        //     state.connection.signer
        //   );

        //   // let bigNumAmount = ethers.utils.parseEther("0.06");
        //   // let valMultiplied = bigNumAmount.mul(qty);
        //   // let valForTransaction = valMultiplied;
        //   let shopAttempt;
        //   try {
        //     shopAttempt = await contractInstance.redeemOwn(tokenIds);
        //   } catch (e) {
        //     dispatch("toasts/add", { message: e?.message ?? e, variant: "error" }, { root: true });
        //     return false;
        //   }

        //   let confirmingSoon = dispatch(
        //     "toasts/add",
        //     {
        //       message: "Claim of familiars will confirm soon",
        //       variant: "success",
        //       timeout: false,
        //       dismissible: false,
        //     },
        //     { root: true }
        //   );

        //   let confirmation = await state.connection.provider.waitForTransaction(
        //     shopAttempt.hash,
        //     true
        //   );

        //   await delay(10000);

        //   dispatch("toasts/remove", confirmingSoon, { root: true });

        //   dispatch(
        //     "toasts/add",
        //     {
        //       message: "Mystic Wizards Familiars claim complete!",
        //       variant: "success",
        //     },
        //     { root: true }
        //   );

        //   return true;
        // },
      },
      getters: {
        isLoggedIn(state) {
          return !!state?.address;
        },
      },
    },
    toasts: {
      namespaced: true,
      state: {
        stack: [],
        index: 1,
      },
      mutations: {
        ADD_TOAST(state, payload) {
          state.index += 1;
          // Vue.set(state, "stack", [...state.stack, payload]);
          state.stack = [...state.stack, payload];
        },
        REMOVE_TOAST(state, payload) {
          let newStack = state.stack.slice();
          const foundIndex = payload.id
            ? newStack.findIndex((val) => val.id === payload.id)
            : newStack.findIndex((val) => val.message === payload.message);
          newStack.splice(foundIndex, 1);
          // Vue.set(state, "stack", [...newStack]);
          state.stack = [...newStack];
        },
        CLEAR_ALL(state) {
          state.stack = [];
          // Vue.set(state, "stack", []);
        },
      },
      actions: {
        add({ commit, state }, payload) {
          let added = payload;
          if (!(payload instanceof Toast)) {
            added = new Toast({ ...payload, id: state.index });
          }

          commit("ADD_TOAST", added);

          return added;
        },
        remove({ commit }, payload) {
          let removed = payload;
          if (!(payload instanceof Toast)) {
            removed = new Toast(payload);
          }

          commit("REMOVE_TOAST", removed);

          return true;
        },
      },
      getters: {
        getStack: (state) => state.stack,
      },
    },
  },
});
