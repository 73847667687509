<script setup>
import ToastComp from "@/components/ToastComp";
import store from "@/store";
import { computed } from "vue";

const toasts = computed(() => {
  return store.getters["toasts/getStack"];
});
</script>

<template>
  <div class="toast-container" aria-live="polite" aria-atomic="true">
    <ToastComp v-for="toast in toasts" :key="toast.id" :toast="toast" />
  </div>
</template>

<style lang="pcss">
.toast-container {
  position: fixed;
  height: 0;
  overflow: visible;
  top: 1rem;
  z-index: 2060;
  left: 50%;
  transform: translateX(-50%);
}
</style>
