class Toast {
  constructor({ id, timeout, message, title, variant, dismissible } = {}) {
    this.timeout = timeout === false ? false : timeout ?? (variant === "error" ? 8000 : 5000);
    this.messages = Array.isArray(message) ? message : [message];
    this.id = id;
    this.title = title;
    this.variant = variant ?? "default";
    this.dismissible = dismissible === false ? false : true;
  }
}

export default Toast;
