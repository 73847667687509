import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import "./styles.css";

import * as VueRouter from "vue-router";

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  { path: "/", component: () => import(/* webpackChunkName: "home" */ "./pages/HomePage.vue") },
  {
    path: "/withdraw",
    component: () => import(/* webpackChunkName: "home" */ "./pages/ManagePage.vue"),
  },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: VueRouter.createWebHistory(),
  routes, // short for `routes: routes`
});

const app = createApp(App).use(store);

app.use(router);

app.mount("#app");
